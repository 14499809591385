import React from "react"
import tw from "twin.macro"
import imageOndas from "../../../images/ondas.svg"

const ContentCard = ({
  style = {},
  children,
  className = "",
  haveOverflow = false,
  havePadding = true,
}) => (
  <div
    css={[
      tw` text-blackest relative font-medium text-base  w-full   rounded-ultra shadow-lg relative`,
      havePadding ? tw`py-8 px-2 lg:px-4 xl:px-6` : "",
      haveOverflow ? "overflow-hidden" : "",
    ]}
    style={{
      backgroundImage: `url(${imageOndas})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}
  >
    {children}
  </div>
)

export default ContentCard
